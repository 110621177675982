import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-project1',
  standalone: true,
  imports: [],
  templateUrl: './portfolio-project1.component.html',
  styleUrl: './portfolio-project1.component.scss'
})
export class PortfolioProject1Component {

}
