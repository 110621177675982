<section id="aboutMe">
  <div class="twoCol">
    <div id="leftBox">
      <span id="headline">About me</span>
      <p id="aboutMeTxt">
        I am a Front-end deverloper based in München, Germany. I help designers
        and companies to bring their ideas to real life. Creativity and logical
        thinking are skills that I have developed to connect ideas into quality
        products. If you think I’d be a good match for your projects, contact
        me!
      </p>
      <div class="w-100">
        <a href="#contactForm"><span id="btn">Let's talk</span></a>
      </div>
    </div>
    <div id="devImg">
      <div>
        <img src="./assets/img/photo.png" alt="" />
      </div>
    </div>
  </div>
</section>
