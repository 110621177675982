<app-above-the-fold/>
<app-divider-section/>
<app-about-me/>
<app-divider-section-arrow-1/>
<app-my-skills/>
<app-divider-section-arrow-2/>
<app-portfolio-header/>
<app-portfolio-project1/>
<app-portfolio-project2/>
<app-contact-form/>
<app-mobile-menu/>