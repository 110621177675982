<section>
    <div id="projectImg">
        <div id="imageHolder">
            <img class="portfolioArrow" src="./assets/img/portfolioArrow.png" alt="">
            <img id="projectImage" src="./assets/img/portfolioSharkie.png" alt="">
        </div>
        
    </div>
    <div class="potfolioDate">
        01/05
        <div class="portfolioDesc">
            <span class="portfolioDescHeader">El Pollo Loco</span>
            <span class="portfolioDescTech">Angular | Typescript | HTML | CSS | Firebase</span>
            <span class="portfolioDescText">Task manager inspired by the Kanban System. Create and organize tasks using drag and drop functions, assign users and categories.</span>
            <span id="btn">Github</span>
        </div>
    </div>
</section>








