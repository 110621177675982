<form id="contactForm" (submit)="sendMail()" ngNativeValidate #myForm>
  <div class="input-container">
    <input required name="name" placeholder=" " type="text" #nameField />
    <label>Your Name</label>
  </div>
  <div class="input-container">
    <input required type="email" placeholder=" " name="email" #emailField />
    <label>Your Email</label>
  </div>
  <div class="input-container">
    <textarea
      required
      name="message"
      placeholder=" "
      id=""
      cols="30"
      rows="10"
      #messageField
    ></textarea>
    <label>Your Message</label>
  </div>

  <div id="checkboxContainer">
    <label class="container"
      >I've read the <a id="privacyPolicy" href="#">privacy policy</a> and agree
      to the processing of my data as outlined.
      <input type="checkbox" />
      <span class="checkmark"></span>
      <span class="hover-circle"></span>
    </label>
  </div>

  <button #sendButton>Send Message</button>
</form>
