<section id="mySkills">
    <div id="skillContainer">
        <div id="skillBoxHeader">My Skills</div>
        <img class="skill" src="./assets/img/angularSkill.png" alt="">
        <img class="skill" src="./assets/img/typescriptSkill.png" alt="">
        <img class="skill" src="./assets/img/javascriptSkill.png" alt="">
        <img class="skill" src="./assets/img/htmlSkill.png" alt="">
        <img class="skill" src="./assets/img/cssSkill.png" alt="">
        <img class="skill" src="./assets/img/firebaseSkill.png" alt="">
        <img class="skill" src="./assets/img/gitSkill.png" alt="">
        <img class="skill" src="./assets/img/scrumSkill.png" alt="">
        <img class="skill" src="./assets/img/restSkill.png" alt="">
        <img class="skill" src="./assets/img/materialSkill.png" alt="">
    </div>
</section>
