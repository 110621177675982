<section id="top">
  <div class="header-wrapper">
    <span class="headline">Frontend</span>
    <span class="headline">Developer</span>
    <div class="name">
      <div id="line"></div>
      <span>David Hollerwöger</span>
    </div>
    <div class="button">Send a Message</div>
  </div>
</section>
