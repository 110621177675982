<section>
    <div id="personal">
        <img src="./assets/img/logoFooter.png" alt="">
        <span>© Stefan Engel 2024</span>
    </div>
    <div id="contact">
        <div id="socialMedia">
            <img src="./assets/img/github.png" alt="">
            <img src="./assets/img/email.png" alt="">
            <img src="./assets/img/linkedin.png" alt="">
        </div>
        <a id="legalNotice" style="color:white;" href="#">Lega<span class="underlined">l notice</span></a>
    </div>
</section>
