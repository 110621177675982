<nav id="mobileMenu" [ngClass]="{'show': menuOpen}">
    <section id="headerSection">
        <img src="./assets/img/logo.png" alt="">
        <img [src]="currentImage" (click)="toggleMenuFunc()" alt="">
    </section>
    <section id="linkSection">
        <a href="#aboutMe">About me</a>
        <a href="#mySkills">My skills</a>
        <a href="#portfolio">Portfolio</a>
    </section>
    <div id="navFooter">
        <div id="sectionContainer">
            <span id="footerHeading">Say Hi!</span>
            <div id="lastCol">
                <a id="footerMail" href="mailto:hdateam90&#64;gmail.com">hdateam90&#64;gmail.com</a>
                <div id="logoContainer">
                    <img src="./assets/img/github.png" alt="">
                    <img src="./assets/img/github.png" alt="">
                    <img src="./assets/img/github.png" alt="">
                </div>
            </div>
        </div>
    </div>
</nav>
