<section>
  <div class="image-container">
    <img
      [src]="currentImage"
      (mouseenter)="changeImageOnHover()"
      (mouseleave)="resetImage()"
      alt=""
    />
  </div>
</section>
